.box {
  background-color: #fff;
  border: 1px solid #d5d5d5;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0);
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0);
  border-radius: 0em;
  margin: -1px auto 0px auto;
  width: 100%;
  max-width: 1250px;
  min-width: 1100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
/*# sourceMappingURL=overview.css.map */