@import "../../styles/partials/variables";

.css_header_sidebar{
  width: 72px
}

/* Position and sizing of burger button */
.bm-burger-button {
  width: 20px;
  height: 20px;
  position: absolute;
  margin-top: 5px;
  margin-left: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.header {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #fafafa;
  // padding: 2.5em 1.5em 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-header {
  color: #b8b7ad;
  padding: 40px;
}

/* Individual item */
.bm-item {
  display: inline-block;
  text-decoration: none;
  color: #333;
  position: relative;
  font-size: 14px;
  padding: 0 1.5rem;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.sidebar-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px !important;
  height: 80px !important;
  background-color: #1f94f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // background-image: url("../../assets/logos/logo.png");
  .logo-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 28px !important;
    font-weight: 600 !important;
    padding-left: 70px;
    cursor: default;
  }
}
.bm-item-list {
  padding-top: 80px;
  .bm-item {
    & > a {
      cursor: pointer;
      display: block;
      padding: 1em 2.5em;
      text-decoration: none;
      color: #696969;
      font-size: 14px;
      position: relative;
      &::before {
        display: inline-block;
        content: "";
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px 24px;
        vertical-align: middle;
        position: absolute;
        left: 0px;
        top: 10px;
      }
    }

    &.shipping-instruction a::before {
      background-image: url("../../assets/icons/main-icons.svg#landing_shipping_instruction_dark");
    }
    &.progress-overview a::before {
      background-image: url("../../assets/icons/main-icons.svg#landing_progress_overview_dark");
    }
    &.carrier-booking a::before {
      background-image: url("../../assets/icons/main-icons.svg#landing_carrier_booking_dark");
    }
    &.container-trace a::before {
      background-image: url("../../assets/icons/main-icons.svg#landing_container_trace_dark");
    }
    &.shipment-trace a::before {
      background-image: url("../../assets/icons/main-icons.svg#landing_shipmentrace_dark");
    }
    &.document-booking a::before {
      background-image: url("../../assets/icons/main-icons.svg#landing_document_dark");
    }
    &.sql-notebook a::before {
      background-image: url("../../assets/icons/main-icons.svg#landing_sql_notebook_dark");
    }

    //Hover efect for all items except the header
    &:not(.sidebar-header):hover {
      background-color: rgba(29, 148, 242, 0.5);
    }
  }
}