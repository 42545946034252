.remember-me {
    float: left;
  }
  .forgot-password {
    cursor: pointer;
    float: left;
    color: #D25B5B;
    position: absolute;
    font-size: 13px;
    font-weight: bold;
    margin-left: 11px;
    margin-top: 3px;
    // opacity: 10;
  }
  .login-button-right {
    position: relative;
      box-sizing: border-box;
      height: auto !important;
      display: block;
      padding: 4% 5% !important;
    //float: right !important;
    width: 100% !important;
    background: #0C528E !important;//#0B3182 !important;
   // box-shadow: 0 5px 10px #999;
    font-weight: 100 !important;
    text-indent: -5%;
    font-size: 15px !important;
    color: #fff !important;
    border-radius: 20px !important;
    border-color: transparent !important;
  }
  .signup{
    background: #f16d29 !important; //#be671f !important;
  }
  .shipment-search {
    margin: auto;
    display: inherit;
  }
  .shipment-trace-input {
    border-radius: 50px !important;
    font-size: 14px;
    height: 30px;
    border-color: #41719C;
    border-style: solid;
    padding-left: 15px;
    width: 100%;
  }
  .shipment-trace-input:focus{
    outline: none;
  }
  .shipment-trace-button {
    margin-left: 8px;
    background-color: #4F95E1 !important;
  }
  .shipment-input-button {
    display: flex;
  }
  .shipment-radio {
    display: flex;
    color: black;
    font-weight: bold;
    font-size: small;
    margin-top: 5px;
  }
  .shipment-radio-input {
    margin-top: -3px;
  }
  .shipment-radio-span {
    margin-right: 15px;
  }
  .shipment-radio-span:last-child {
    margin-right: 0px;
  }
  .shipment-type {
    display: inline
  }
  .forgot-password:hover {
    color: #196eb8;
  }
  .login-selections {
    margin-bottom: 1%;
    margin-top: 6%;
  }
  .checkbox {
    width: 100%;
    margin-bottom: 15%;
    height: max-content;
  }
  .cssPage {
    text-align: right;
    display: flex;
    background-color: #E4E4E4 !important;
  }
  .card-container{
    height: 32vh;
      width: 74vw;
      position: absolute;
      bottom: 4vh;
      display: flex;
      background: #E4E4E4;
      opacity: 1;
      padding-left: 2vw;
      padding-top: 3vh;
      align-items: center;
    // height: 30.9vh;
    // width: 75vw;
    // position: absolute;
    // bottom: 39px;
    // display: flex;
    // z-index: 99999999999999;
    // background: #C4E4FD;
    // opacity: 1;
    // padding-right: 36px;
  }
  .logo-div {
    //background: url("../../assets/logos/nlogo.png");
    background: url("../../assets/images/kwe_logo.svg");
    min-height: 17vh;
    width: 16vw;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .logoSubText-initial{
      font-style: italic;
      font-weight: bolder;
      color: black;
      font-size: 20px;
  }
  .form-control {
    padding: 4% 5% !important;
    margin-bottom: 10% !important;
    font-size: 12px !important;
    border: 1px solid #ddd !important;
    border-radius: 3px !important;
  }
  .checkbox-round-checked {
    width: 1.3em;
    height: 1.3em;
    background: url("../../assets/icons/main-icons.svg#check-preview");
    border-radius: 22% !important;
    vertical-align: middle;
    border: 1.2px solid #1F94F2;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 22% !important;
    vertical-align: middle;
    border: 1.2px solid #1F94F2;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }
  .remember-label {
    color: #336D9F;
    font-size: 14px;
  }
  .btn-arrow-img {
    margin-left: 5px;
    width: 20px;
    height: 20px;
  }
  .forgot-password-div {
    padding: 5% 0;
  }
  .forgot-password-link {
    font-weight: 500;
    font-size: 15px;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    border: none;
  }
  .forgot-password-link:hover{
    color: inherit
  }
  .padding-top-10{
    padding-top: 28vh;
  }
  .footerDiv-css{
    height: 4vh;
    background: #1F94F2 !important;
    position: relative;
    .login-footer {
      position: absolute;
      color: #fff;
      padding-left: 22px;
      width: 100%;
      font-size: 12px !important;
      top: 48%;
      transform: translateY(-50%);
    }
  }
  .headerSpan{
    font-weight: bold;
    font-size: 1.7rem;
    // font-size: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-end;
  }
  .login-form-container-css{
    max-width: 300px;
    width: 75%;
    text-align: center;
    margin: auto 6vw;
    padding-top: 31vh;
    .form-signin{
      margin-top: 10px;
    }
   .form-control {
      border-radius: 15px !important;
      border: 1.5px solid #1F94F2 !important;
      margin-bottom: 6% !important;
      background-color: #F2F2F2;
    }
    .form-control:focus{
      background-color: #F2F2F2;
    }
  }
  .right-div-css{
    background: url(../../assets/images/white_oval.png) no-repeat;
    float: right;
    background-size: 100% 100%;
    width: 27vw;
    position: absolute;
    right: 0;
    height: 96vh;
    // z-index: 99999999999;
    min-width: 390px;
  }
  .kwe-logo-css{
    height: auto;
    width: 120px;
    float: right;
    padding: 2px;
    margin: 1.5vw 2.6vw;
  }
  .ufs-text-image{
      padding: 0 5px !important;
      transform: scale(1);
      height: 40px;
  }
  .underline{
    height: 1.5vh;
    width: 100%;
    margin-top: -10px;
  }
  .password{
  border: none !important;
  width: 59%;
  margin-bottom: 0 !important;
  background-color: #F2F2F2;
  }
  .password-container{
    text-align: start;
    position: relative;
    padding: 3% 5% !important;
  }
  .password-container input:focus{
    outline: none;
  }
  .rem-me-span{
    margin-left: 4px;
      vertical-align: middle;
  }
  
  .fadein-css img {
    background-color: black;
    position:absolute;
    left:-65px;
    top:0;
    -webkit-animation-name: fade;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 23s;
    animation-name: fade;
    animation-iteration-count: infinite;
    animation-duration: 23s;
    height: 63.1vh;
    width: 93vw;
  }
  @-webkit-keyframes fade {
    0% {opacity: 0;transform: scale(1.2,1.2);}
    10% {opacity: 1;transform: scale(1.1,1.1);}
    25% {opacity: 1;transform: scale(1,1);}
    35% {opacity: 0;}
    100% {opacity: 0;}
  }
  @keyframes fade {
    0% {opacity: 0;transform: scale(1.2,1.2);}
    10% {opacity: 1;transform: scale(1.1,1.1);}
    35% {opacity: 1;transform: scale(1,1);}
    45% {opacity: 0;}
    100% {opacity: 0;}
  }
  
  // #i1 {
  //   -webkit-animation-delay: -12s;
  // }
  #i2 {
    -webkit-animation-delay: -5s;
    animation-delay: -5s;
  }
  #i3 {
    -webkit-animation-delay: -10s;
    animation-delay: -10s;

  }
  #i4 {
    -webkit-animation-delay: -15s;
    animation-delay: -15s;

  }
  .longer-underline{
      width: 88%;
      position: absolute;
      height: 1.1vh;
      bottom: 35.9vh;
  }
  .tiles{
    height: 25vh;
    width: 17vw;
    // border: 1px solid #B6D4EB;
    margin: auto;
    // background-image: radial-gradient(circle at top right, #C4E4FD, #54AFF6);//linear-gradient(to bottom left, #C4E4FD, #54AFF6);
      // box-shadow: 0px 3px 8px rgb(100,100,98);
  }
  .tileClass{
    margin: auto;
      height: 23vh;
      width: 12vw;
      display: flex;
      flex-direction: column;
      color: #0C528E;
      margin-top: 5px;
  }
  .headingText{
    font-size: 30px;
      float: left;
      font-weight: 1000;
      font-family: Avenir-Light, Helvetica, sans-serif;
  }
  .tileImage{
    height: 6vh;
    width: auto;
    margin-top: 3px;
   // margin-top: -5px;
  }
  .quote{
    transform: scale(0.85);
  }
  .tileMsg{
    width: 12vw;
    text-align: left;
    color:#555;// #767171;
    font-size: 19px;
    font-family: Agency FB, Helvetica, sans-serif;
  }
  .k-i-loading,
  .k-loading-image {
      position: relative !important;
      margin-left: -16px;
      z-index: 99999999999999;
  }
  .sign-up-css{
    cursor: pointer;
    width: 133px;
    height: 28px;
    color: gray;
    text-align: center;
    border: 1.5px solid gray;
    border-radius: 5px;
    position: absolute;
    z-index: 999999;
    margin-top: 9px;
    font-size: 13px;
    margin-left: 9px;
    padding-top: 2px;
  }
  
  .k-dialog-titlebar.k-header{
    text-align: left;
  }
  
  .k-dialog-content{
    text-align: left;
  }
  .footnote{
    color: #767171;
    font-size: 11px;
    position: absolute;
    bottom: 10px;
    right: 10%
  }
  
  // .sign-up{
  //   cursor: default;
  //   width: 133px;
  //   height: 28px;
  //   // color: white;
  //   text-align: center;
  //   // border: 1.5px solid white;
  //   -webkit-animation: bgcolorchange 10s infinite; /* Chrome, Safari, Opera */ 
  //   animation: 11s infinite bgcolorchange;
  //   // -webkit-animation-delay: -5s;
  //    animation-delay: -5s;
  //   border-radius: 5px;
  //   position: absolute;
  //   z-index: 999999;
  //   margin-top: 9px;
  //   font-size: 13px;
  //   margin-left: 9px;
  //   padding-top: 2px;
  // }
  
  
   
  // @keyframes bgcolorchange {
  //   0% {
  //     color: white;  border: 1.5px solid white;
  //   }
  //   49% {
  //     color:white; border: 1.5px solid white;
  //   }
  //   50% {
  //     color: #767171; border: 1.5px solid #767171;
  //   }
  //   100% {
  //     color:#767171; border: 1.5px solid #767171;
  //   }
  // }
  .tile-flex
  {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tileImage{
      height: 6vh;
      width: auto;
      margin-top: 3px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      padding: 0 13px;
     // margin-top: -5px;
    }
  }
  .no-padding{
    padding: 0 !important;
  }
  .padding-10{
    padding: 10px;
  }
  .mt-55{
    margin: 55px 0 0 0 !important;
    justify-content: center;
    // transform: scale(1.3);
  }
  .no-margin{
    margin: 0 !important;
  }

.kwe-emission-link {
  font-size: 15.7px;
  font-weight: bold;
  position: absolute;
  bottom: 30px;
  right: 10%;
  text-align: right;
  color: #0e74c7;
}

.kwe-emission-link a {
  color: #0e74c7 !important;
}
