.modal-dlg-wd-header .k-i-x::before {
    color: #a1a1a1;
}

.msg-info {
    display: flex;
    padding: 0px 12px;
}

.msg-content {
    padding: 10px 10px 0 10px;
    white-space: pre-wrap;
    min-width: 300px;
    max-height: calc(100vh - 300px);
    overflow-y: auto;
}

.cfrm-dlg-action-bar{
    padding: 0px 10px;
    & input[type='button']{
        margin: 10px;
    }
    display: flex
}

@mixin ted-msg-icon($background) {
    width: 35px;
    height: 35px;
    display: inline-block;
    margin-right: 10px;
    background: url($background) no-repeat;
    background-size: cover;
}

.k-icon.k-i-error {
    font-size: 32px;
    color: #ff1a1a;
}

.k-icon.k-i-warning {
    font-size: 32px;
    color: #ff9932;
}

.k-icon.k-i-information {
    font-size: 32px;
    color: #1F94F2;
}

.ted-dialog {
    .k-dialog-buttongroup {
        padding: 0px 10px;
    }
}

.ted-dialog-btn { 
    margin: 10px;
}

