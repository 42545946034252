@charset "utf-8";

/* CSS Document */
body,
html {
    height: 100%;
    overflow: hidden;
}

body {
    background-color: #FAFAFA;
    line-height: 1.5;
    font-family: Arial, Helvetica, sans-serif;
    color: #222;
    font-size: 14px;
}

header {
    width: 100%;
    height: 77px;
    background-color: #FFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, .2);
}

#header {
    height: 40px;
    position: relative;
    background-color: #1F94F2;
}

#menu {
    width: 40px;
    height: 40px;
    position: fixed;
    top: 0;
    left: 0;
    background-image: url(../assets/icons/main-icons.svg#menu);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;
    cursor: pointer;
}

#header #h2 {
    position: absolute;
    top: 0;
    left: 135px;
    height: 100%;
    line-height: 40px;
    font-size: 18px;
    /*font-weight:bold;*/
    color: #FFF;
}

#header h1 {
    height: 100%;
    position: absolute;
    top: 0;
    left: 55px;
    color: white;
    font-size: 20px;
    font-weight: 400;
}

#header h1 a {

        display: block;
        height: 100%;
        width: 100%;
        margin-top: 5px;
        color: #fff;
        font-size: 23px;
        font-weight: 550;
        text-decoration: none;
    }

#header #account {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    background-image: url(../assets/icons/main-icons.svg#account    );
    background-repeat: no-repeat;
    background-position: center;
    background-size: 13px;
    cursor: pointer;
}

nav {
    height: 37px;
    position: relative;
}

nav ul {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
}

nav ul li {
    height: 100%;
    display: inline-block;
    position: relative;
}

nav ul li a {
    color: #999;
    text-decoration: none;
    padding: 0 1em;
    line-height: 37px;
    display: block;
    font-size: 14px;
}

nav ul li a span {
    display: inline-block;
    height: 14px;
    background-color: #999;
    vertical-align: middle;
    color: #FFF;
    line-height: 14px;
    padding: 0 .7em;
    border-radius: .3em;
    margin-left: .5em;
    font-size: 70%;
}

nav ul li.select:after {
    display: block;
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #888888;
    content: "";
}

nav ul li.select a {
    color: #666;
    font-weight: bold;
}

nav p {
    height: 100%;
    line-height: 37px;
    margin-left: 15px;
    margin-right: 15px;
    display: inline-block;
    vertical-align: top;
}

#container nav p {
    height: 100%;
    line-height: 37px;
    margin-left: 0px;
    margin-right: 0px;
    display: inline-block;
    vertical-align: top;
}

nav p span {
    font-weight: bold;
}

nav .caption {
    margin-left: calc(200px + 0%);
}

#container nav .caption {
    margin-left: 0px;
}

header.siSearch,
#container header {
    height: 100px;
}

header.siSearch nav,
#container nav {
    height: 60px;
    line-height: 60px;
    text-align: center;
}

header.siSearch .caption {
    padding: 0 20px 0 50px;
}

header.siSearch .caption:before,
#container header .caption:before {
    width: 60px;
    height: 60px;
    content: "";
    display: inline-block;
    background-image: url(../assets/icons/main-icons.svg#search);
    background-repeat: no-repeat;
    background-size: 20px auto;
    background-position: center;
    vertical-align: middle;
}

.shipment-search-icon {
    width: 25px;
    height: 25px;
    border-radius: 100px !important;
    color: #FFF;
    content: "";
    display: inline-block;
    background-image: url(../assets/icons/main-icons.svg#searchIcon);
    background-repeat: no-repeat;
    background-size: 20px auto;
    background-position: center;
    vertical-align: middle;
}

nav p.status span {
    display: inline-block;
    background-color: #E84C3D;
    color: #FFF;
    height: 16px;
    padding: 0 1em;
    line-height: 16px;
    border-radius: 10px;
    margin-left: .5em;
    font-size: 11px;
    vertical-align: middle;
}

nav#side {
    height: calc(100% - 40px);
    width: 200px;
    position: fixed;
    top: 40px;
    left: 0;
    background-color: #FAFAFA;
    border-right: 1px solid #ddd;
    box-sizing: border-box;
    padding: 20px;
    z-index: 2;
}

nav#side a {
    display: block;
    color: #666;
    text-decoration: none;
    height: 40px;
    line-height: 40px;
}

nav#side a.create {
    height: 44px;
    background-color: #FFF;
    display: block;
    line-height: 44px;
    border-radius: 22px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, .2);
    color: #333;
    text-decoration: none;
    padding: 0 20px;
    font-size: 120%;
    margin-bottom: 10px;
}

nav#side a.search {
    background-image: url(../assets/icons/main-icons.svg#search);
    background-repeat: no-repeat;
    background-position: calc(20px + 0em) center;
    background-size: 15px auto;
    padding-left: calc(20px + 1.5em);
}


.account {
    padding: 20px 30px;
    background-color: #FFF;
    border-radius: .2em;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, .3);
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 99;
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 0;
    -moz-transform-origin: 100% 0;
    -o-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;

    -moz-transition: opacity 0.3s ease 0s, -moz-transform 0.3s cubic-bezier(.07, .91, .14, .84) 0s;
    -webkit-transition: opacity 0.3s ease 0s, -webkit-transform 0.3s cubic-bezier(.07, .91, .14, .84) 0s;
    -o-transition: opacity 0.3s ease 0s, -o-transform 0.3s cubic-bezier(.07, .91, .14, .84) 0s;
    -ms-transition: opacity 0.3s ease 0s, -ms-transform 0.3s cubic-bezier(.07, .91, .14, .84) 0s;
    transition: opacity 0.3s ease 0s, transform 0.3s cubic-bezier(.07, .91, .14, .84) 0s;

}

.open4 .account {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.account a {
    font-size: 130%;
    color: #999;
    font-weight: normal;
    display: block;
    text-decoration: none;
}

.account a:after {
    content: "";
    display: inline-block;
    height: 20px;
    width: 5px;
    background-image: url(../assets/icons/main-icons.svg#next);
    background-size: 5px 8px;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
    opacity: .6;
    margin-left: .5em;
}

.account p {
    font-size: 80%;
    color: #666;
}

/* #header #nort {
    width: 40px;
    height: 40px;
    position: absolute;
    background-image: url(../assets/icons/notifications.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 13px;
    cursor: pointer;
    display: flex;
    
    
}

#header #nort span {
    width: auto;
    min-width: 14px;
    height: 14px;
    position: absolute;
    top: 3px;
    right: 3px;
    border-radius: 50%;
    display: block;
    text-align: center;
    line-height: 14px;
    font-size: 10px;
    color: #FFF;
    background-color: #ffa400;
    padding: 0 .4em;
    box-sizing: border-box;
} */

#header #user-info {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 40px;
    background-image: url(../assets/icons/main-icons.svg#notifications);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 13px;
    cursor: pointer;
}

#header.nort {
    padding: 0px 0px 20px 0px;
    background-color: #FFF;
    border-radius: .2em;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, .3);
    position: fixed;
    top: 10px;
    right: 40px;
    z-index: 99;
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 0;
    -moz-transform-origin: 100% 0;
    -o-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;

    -moz-transition: opacity 0.3s ease 0s, -moz-transform 0.3s cubic-bezier(.07, .91, .14, .84) 0s;
    -webkit-transition: opacity 0.3s ease 0s, -webkit-transform 0.3s cubic-bezier(.07, .91, .14, .84) 0s;
    -o-transition: opacity 0.3s ease 0s, -o-transform 0.3s cubic-bezier(.07, .91, .14, .84) 0s;
    -ms-transition: opacity 0.3s ease 0s, -ms-transform 0.3s cubic-bezier(.07, .91, .14, .84) 0s;
    transition: opacity 0.3s ease 0s, transform 0.3s cubic-bezier(.07, .91, .14, .84) 0s;

}

