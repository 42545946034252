@import "./variables";

.webkit-scrollbar {
    height: $scrollbar-width;
    width: $scrollbar-width;
    background-color: $scrollbar-bg;
}

.webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-bg;
    border-radius: 10px;

    &:hover {
        background-color: #acabab;
    }
}

.webkit-scrollbar-track {
    border-radius: $scrollbar-width;
    background-color: $scrollbar-track-bg;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

//firefox 
.moz-based-scroll {
    scrollbar-color: #a1a1a1 #f5f5f5;
    scrollbar-width: thin;
}