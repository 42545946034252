$base-font-size: 14px;
$base-btn-font-size: 12px;
$base-component-height: 24px;
$base-component-child-input-height: 22px;
$base-border-radius: 2px;
$base-color: #1d94f2;
$base-color-hover: #1264a5;
$base-reverse-color: #989898;
$base-focus-color: #1d94f2;
$base-font-color: #000000;
$base-component-border-color: #c8c8c8;

//grid
$base-grid-header-bg-color:#d8d8d8;
$base-grid-header-border-color:#8e8e8e;
$base-grid-border-color: #dddddd;
$base-grid-row-alt-bg-color:#eeeeee;
$base-grid-width: 1320px;

//scrollbar
$scrollbar-width: 10px;
$scrollbar-bg: #f0f0f0;
$scrollbar-thumb-bg: #a1a1a1;
$scrollbar-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
$scrollbar-track-bg: #f5f5f5;
