$headerBlue: #1f94f2;

.css_header{
margin-top: 8px;
cursor: default;
width: 70px;
}

header {
  height: 40px;
  background-color: $headerBlue;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.burger-button{
  width: 20px;
  height: 20px;
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
}

.user-profile {
  float: right;
  width: 20px;
  height: 20px;
  margin: 0.5%;
  background-image: url(../../assets/icons/main-icons.svg#account);
  background-repeat: no-repeat;
  cursor: pointer;
  vertical-align: middle;
}

.logout {
  cursor: pointer;
  margin-right: 5px;
}

.logout:hover {
  color: #0e5086;
}

.header-nav {
  background-color: white;
}

.header-popup {
  background-color: white;
  opacity: 1;
  padding: 0.5%;
  border-radius: 3px !important;
  margin: 0.5%;
  width: 12vw;
  top: 0px !important;
}

#profilePopupDiv {
  text-align: center;
}

.header-popup .k-popup {
  border: none !important;
  background-color: transparent !important;
  // color: darkgray;
  // width: -webkit-max-content;
  // width: -moz-max-content;
  font-weight: 500;
}

.header-popup-content {
  padding: 1%;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}



.notificationHeader {
  font-weight: bold;
  color: black;
  font-size: 140%;
  padding: 0px 0px 10px 0px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.17);
  border-bottom: 1px solid #ddd;
  margin-bottom: 0.8em;
  margin-top: 11px;
  margin-left: 11px;
}

.settingsHeader {
  font-weight: bold;
  color: black;
  font-size: 140%;
  padding: 0px 0px 10px 0px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.17);
  border-bottom: 1px solid #ddd;
  margin-bottom: 0.8em;
  margin-top: 11px;
  margin-left: 11px;
  text-align: center;
}

.notificationFooter {
  margin: 10px 15px 22px;
  float: right;
}

.notificationBody {
  margin: 36px 13px 16px;
}

.footerLink {
  margin-left: 20px;
  cursor: pointer;
  color: #1f94f2;
}

.notif-icon {
  background-size: 25px;
  height: 25px;
  width: 45px;
  background-color: transparent;
}

.bodyIcons {
  float: right;
  margin-right: 21px;
  display: flex;
}

.bodyIcons .icons-group span {
  background-color: #fd5621;
  width: 15px;
  height: 15px;
  top: -32.5px;
  right: -15.5px;
  display: block;
  color: #fff;
  font-size: 10px;
  text-align: center;
  line-height: 15px;
  border-radius: 50%;
  position: relative;
}

.icons-group {
  margin-left: 10px;
}

.bodyRow {
  padding: 0px 0px 17px 0px;
  margin-top: 25px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: 0.8em;
}

.userProfileArrow {
  display: inline-block;
  height: 20px;
  width: 5px;
  background-image: url(../../assets/icons/main-icons.svg#next);
  background-size: 5px 8px;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  opacity: 0.6;
  margin-left: 0.5em;
  cursor: pointer;
}

#setting {
  width: 45px;
  height: 60px;
  background-image: url(../../assets/icons/main-icons.svg#setting);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
  text-indent: -9999px;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}

#return {
  width: 45px;
  height: 60px;
  background-image: url(../../assets/icons/main-icons.svg#back);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;
  text-indent: -9999px;
  position: absolute;
  top: 7.5px;
  left: 0px;
  cursor: pointer;
}

.check2 {
  display: inline-block;
  height: 25px;
  width: 40px;
  vertical-align: middle;
  position: relative;
}
.check2 input {
  width: 100%;
  height: 25px;
  -webkit-appearance: none;
  appearance: none;
  opacity: 0;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.check2 .swich {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.check2 .swich .bar {
  height: 15px;
  width: 100%;
  position: absolute;
  top: 5px;
  left: 0;
  background-color: #b0afaf;
  border-radius: 7.5px;
  -moz-transition: background-color 0.3s ease 0s, left 0.3s ease 0s;
  -webkit-transition: background-color 0.3s ease 0s, left 0.3s ease 0s;
  -o-transition: background-color 0.3s ease 0s, left 0.3s ease 0s;
  -ms-transition: background-color 0.3s ease 0s, left 0.3s ease 0s;
  transition: background-color 0.3s ease 0s, left 0.3s ease 0s;
}
.check2 .swich .cir {
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0px;
  left: 0;
  -moz-transition: background-color 0.3s ease 0s, left 0.3s ease 0s;
  -webkit-transition: background-color 0.3s ease 0s, left 0.3s ease 0s;
  -o-transition: background-color 0.3s ease 0s, left 0.3s ease 0s;
  -ms-transition: background-color 0.3s ease 0s, left 0.3s ease 0s;
  transition: background-color 0.3s ease 0s, left 0.3s ease 0s;
}
.check2 input:checked + .swich .bar {
  background-color: #97cdf9;
}
.check2 input:checked + .swich .cir {
  left: 15px;
  background-color: #1f94f2;
}

.userProfile {
  // font-size: 20px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.vertLine {
  margin: 0px 10px;
  font-size: x-large;
}

.terminal {
  font-size: smaller;
}

.userDetailsContainer {
  color: white;
  //text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 6px;
  .userInfo {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: flex-end;
    line-height: 1.25;
  }
  .notification {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    #nort {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: flex-end;
      padding-top: 2px;
      padding-right: 25px;
      align-content: center;
      margin-right: 6px !important;
      //position: absolute;
      //background-image: url(../../assets/icons/notifications.svg);
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: 14px;
      background-size: 13px;
      cursor: pointer;

      #nort-span {
        position: absolute;
        margin-right: -12px;
        margin-left: 14px;
        margin-top: 4px;
        display: flex;
        width: auto;
        min-width: 14px;
        height: 14px;
        border-radius: 50%;
        text-align: center;
        font-size: 10px;
        color: #fff;
        background-color: #ffa400;
        padding: 0 0.4em;
        box-sizing: border-box;
      }
    }
  }
}

.bell {
  cursor: pointer;
  margin-left: 10px;
  padding-right: 20px;
}