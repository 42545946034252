.ted-overlay-screen {
  background-color: #fff;
  width: 100%;
  height: calc(100vh - 40px);
  z-index: 20;
  position: absolute;
  top: 40px;

  .overlay-btn-holder {
    position: absolute;
    top: 4rem;
    z-index: 1000;
    .overlay-back-btn {
      padding-top: 0px;
      padding-bottom: 0px;
      background-color: #fff;
      color: #1d94f2;
      border: 1px solid #1d94f2 !important;
      font-size: 12px;
      box-shadow: none;
      width: 65px;
      text-transform: capitalize;
      margin: 0px 10px;
    }
  }
}
