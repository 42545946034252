.version{
  position: fixed;
  bottom: 0;
  right: 12px;
  margin: auto;
  padding: 5px;
  font-size: 10px;
  color: #5091cd;
}
.version-login{
  position: absolute;
  right: 12px;
  margin: auto;
  padding: 5px;
  font-size: 12px;
  color: white;
  top: 50%;
  transform: translateY(-50%);
}