@charset "utf-8";
/* CSS Document */
#side,
header,
#si ul.tab,
#si .subHeader,
#si a.edit,
#container a.edit,
.confirm,
.pager {
  display: none;
}
#si #wrapper {
  width: 100%;
  max-width: 100%;
  margin: 0;
  height: auto;
}
#si #step5 .box {
  border: none;
  box-shadow: none;
  max-width: 100%;
}
#mCSB_1_scrollbar_vertical {
  opacity: 0;
}
body {
  background-color: #fff;
}
.mCSB_container,
.mCustomScrollBox,
#si .scroll,
body,
html {
  overflow: auto;
  height: auto;
}
#si .box .card {
  box-shadow: none;
}
