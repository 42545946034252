.main-div {
    background-image: linear-gradient(#97ccfd, white);
    padding: 7% 20%;
    margin: 0 auto;
    text-align: center;
  }
  .header-message {
    text-align: center;
    margin-top: 12%;
    color: red;
  }
  .text-message {
    text-align: center;
  }
  .navigate-button{
    height: 33px;
  }
  .footer{
    color: #3ba4f4;
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 100%;
    font-size: 13px!important;
  }