@mixin reset-loading {
    all: initial;

    * {
        all: unset;
    }
}

@mixin custom-loading($iconUrl) {
    .k-i-loading,
    .k-loading-image {
        @include reset-loading;
        position: absolute;
        top: 3px;
        right: 4px;
        background-image: $iconUrl;
        background-repeat: no-repeat;
        background-position: top left;
        height: 16px;
        width: 16px;

        &:before {
            @include reset-loading;
        }

        &:after {
            @include reset-loading;
        }
    }
}