.fallback-div {
  background-image: linear-gradient(#97ccfd, white);
  padding: 7% 20%;
  margin: 0 auto;
  text-align: center;
}
.header-message {
  text-align: center;
  margin-top: 12%;
  color: red;
}
.text-message {
  text-align: center;
}
.navigate-button{
  height: 33px;
}