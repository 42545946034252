//@import "~bootstrap/scss/bootstrap";
@import "~@progress/kendo-theme-material/scss/all";
@import "./styles/partials/scrollbar";
@import "./styles/partials/datetimepicker";
@import "./styles/partials/componentloading";

@font-face {
  font-family: 'Avenir-Heavy';
  src: local('Avenir-Heavy'), url(./fonts/Avenir-Heavy.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir-Light';
  src: local('Avenir-Light'), url(./fonts/Avenir-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir-Roman';
  src: local('Avenir-Roman'), url(./fonts/Avenir-Roman.ttf) format('truetype');
}


html,
body {
  height: 100%;
  font-size: 16px !important;
}

body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 12px;
  color: #222;
}

.form-signin {
  width: 100%;
  max-width: 400px;
  padding: 3% 5%;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
#root {
  min-width: 100%;
}
.logo {
  background-image: url("./assets/logos/logo.png");
  background-repeat: no-repeat;
  min-width: 50px;
}
.login-container {
  text-align: center;
  background-color: #012840;
  color: #6a8fa6;
  min-height: 96vh;
}

.scroll {
  width: 200px;
  height: 400px;
  background: red;
  overflow: scroll;
}
.scroll::-webkit-scrollbar {
  width: 12px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.landing-container {
  text-align: center;
  min-height: 100vh;
}

//common styles

.ted-link-btn {
  width: 110px;
  margin: 0 5px;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background-repeat: no-repeat;
}

.ted-link-btn.btn-disabled {
  opacity: 0.6;
}
.ted-link-btn.btn-disabled:hover {
  cursor: default;
}
.ted-link-btn:hover {
  opacity: 0.6;
  cursor: pointer;
}

// Kendo component CSS

// Start Kendo checkbox CSS
.k-checkbox-label {
  padding: 5px 5px 5px 25px;
}
.k-checkbox-label,
.k-radio-label {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.k-checkbox-label::before {
  border-radius: 1px;
  border: solid 1px #c8c8c8;
  background-color: #ffffff;
}
.k-checkbox:focus + .k-checkbox-label::before {
  box-shadow: none;
  border-color: #c8c8c8;
}
.k-checkbox:focus:checked + .k-checkbox-label::before {
  border-color: $base-color;
}
.k-checkbox:checked + .k-checkbox-label::before {
  background-color: $base-color;
  border-color: $base-color;
}
.k-checkbox:checked + .k-checkbox-label::after,
.k-checkbox:indeterminate + .k-checkbox-label::after {
  background-color: $base-color;
  border-color: $base-color;
}
// End Kendo checkbox CSS

.card-block:first-child{
  margin-top: 5px
}

.card-block {
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #cccccc;
  background-color: #ffffff;
  margin: -1px auto 10px auto;
  text-align: left;
  padding: 15px 25px;
  width: 1000px;
  display: block;
  position: relative;
  .card-header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .card-wrap{
    // margin: 0px 20px;
    .card-header-wrapper {
      .ted-header{
        font-size: 1rem;
      }
    }
  }
  .card-block {
    border-bottom: 1px solid #cccccc;
    &:last-child{
      border-bottom: none;
    }
  }
}

.card-block > .icon-wrap {
  display: block;
  text-decoration: none;
  color: #666;
  position: absolute;
  top: 24px;
  right: 24px;
}

.card-block .card-wrap .row:last-child .card-item {
  margin-bottom: 10px;
}

.card-block .card-wrap .card-title {
  font-weight: bold;
  background-color: #eeeeee;
  padding-top: 5px;
  padding-bottom: 5px;
}

.card-block .card-wrap .card-item > .k-textbox,
.card-block .card-wrap .card-item > .k-dropdown,
.card-block .card-wrap .card-item > .k-numerictextbox,
.card-block .card-wrap .card-item > .k-datepicker,
.card-block .card-wrap .card-item > .k-datetimepicker,
.card-block .card-wrap .card-item > .k-autocomplete,
.card-block .card-wrap .card-item > .k-combobox{
  width: 80%;
}
// .card-block .card-wrap .card-item input {
//   font-size: 10px;
// }
.card-block .card-wrap .card-item .k-icon {
  font-size: 1rem;
  margin-left: 20px;
  padding-bottom: 10px;
}

.custom-scrollbar {
  scrollbar-color: rgb(161, 161, 161) #f5f5f5;
  scrollbar-width: thin;
}

.custom-scrollbar::-webkit-scrollbar-track {
  @extend .webkit-scrollbar-track;
}

.custom-scrollbar::-webkit-scrollbar {
  @extend .webkit-scrollbar;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  @extend .webkit-scrollbar-thumb;
}

.card-block .card-wrap .card-item {
   text-align: left;
   margin-bottom: 15px;
  .k-i-edit,
  .k-icon.k-i-search {
    cursor: pointer;
  }
}

//Common label and text.

.ted-required{
  color:#ff0000;
}

.ted-required-field::after {
  color:#ff0000;
  content: "*";
}

.ted-label-text {
  font-size: 0.8rem;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 4px;
}

.ted-additional-text {
  font-size: 0.7rem;
  color: #5a5a5a;
}

.ted-input-text-disabled {
  color: #aaaaaa;
}

.ted-input-text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  color: #000;
}

.ted-header {
  font-size: 1.2rem;
  color: #1D94F2;
  text-align: left;
  padding-bottom: 0.6rem;
}

.search-btn-margin{
margin-left: 7px;
}
button.switch-search-bt{
  margin-left:10px;
  margin-right: 0
}
.moz-border-fix .k-searchbar{
  input{
    box-shadow: none;
  }
}

.ted-i-edit {
  display: inline-block;
  content: " ";
  background-image: url(./assets/icons/main-icons.svg#edit);
  background-repeat: no-repeat;
  background-size: 20px 20px;
  margin-right: 5px; 
  height: 20px;
  width: 20px;
  cursor: pointer;
  // background-color: #ffffff;
}

.ted-i-icon {
  display: inline-block;
  content: " ";
  background-repeat: no-repeat;
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
}

.ted-i-search {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#search);
  cursor: pointer;
  // background-color: #ffffff;
}

.ted-i-search-not-allowed {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#search);
  cursor: not-allowed;
  // background-color: #ffffff;
}

.ted-i-print {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#print);
}

.ted-i-upload {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#upload);
}

.ted-i-prev {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#prev);
}

.ted-i-next {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#next);
}

.ted-i-create {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#create);
}

.ted-i-check {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#check);
}

.ted-i-checked-preview {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#check-preview);
}

.ted-i-unchecked-preview {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#closeHover);
}

.ted-i-close {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#close);
}

.ted-i-close:hover {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#closeHover);
}

.ted-i-completed-black {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#tick_black);
}


.ted-i-completed-grey {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#tick_grey);
}

.ted-i-delayed-grey, .ted-i-delayed-black {
  @extend .ted-i-delayed;
}

.ted-i-delayed {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#delayed);
}

.ted-i-skip-grey, .ted-i-skip-black {
  @extend .ted-i-skip;
}
.ted-i-skip {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#skip);
}

.ted-i-shipping-instruction-blue {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#landing_shipping_instruction_blue);
}

.ted-i-shipping-instruction-white {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#landing_shipping_instruction_white);
}

.ted-i-carrier-booking-blue {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#landing_carrier_booking_blue);
}

.ted-i-carrier-booking-white {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#landing_carrier_booking_white);
}

.ted-i-hbl-blue {
  @extend .ted-i-icon;
  // background-image: url(./assets/icons/module_icons/HBL_Icon_Blue.png);
}

.ted-i-hbl-white {
  @extend .ted-i-icon;
  // background-image: url(./assets/icons/module_icons/HBL_Icon_White.png);
}

.ted-i-mbl-blue {
  @extend .ted-i-icon;
  // background-image: url(./assets/icons/module_icons//MBL_Icon_Blue.png);
}

.ted-i-mbl-white {
  @extend .ted-i-icon;
  // background-image: url(./assets/icons/module_icons/MBL_Icon_White.png);
}

.ted-disabled-i {
  opacity: 0.6;
}

.ted-i-docs-white {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#landing_document_white);
}

.ted-i-docs-blue {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#landing_document_blue);
}

.ted-i-check-blue {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#check-preview);
}
.ted-i-container-blue {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#landing_container_trace_blue);
}

.ted-i-container-white {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#landing_container_trace_white);
}

.ted-i-maximized-comments {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#Maximized_Comments);
  background-size: contain;
  width: 30px;
  height: 20px;
}

.ted-i-minimized-comments {
  @extend .ted-i-icon;
  background-image: url(./assets/icons/main-icons.svg#Minimized_Comments);
  background-size: contain;
  height: 15px;
  width: 15px;
}

.radio-bt-group {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  padding: 3px 5px 5px 5px;

  .radio-bt {
    .k-radio-label {
      padding-top: 2px;
    }
    .k-radio:checked + .k-radio-label::before {
      background-color: white;
      border-color: #1f94f2;
      box-shadow: 0 0 1px 0 white inset;
    }

    .k-radio:checked + .k-radio-label::after {
      background-color: #1f94f2;
    }

    .k-radio-label::after {
      background-color: #1f94f2;
      transition: transform linear 280ms;
    }
  }
  
}

.error-fragment .error,#errorClass .error {
  border: 0.5px solid red !important;
  border-color: rgba(243, 23, 0, 0.5) !important;
}
 
 
.error > span, .error > .k-dropdown-wrap.k-state-focused, .error > .k-numeric-wrap:focus-within, .error .k-picker-wrap, .error .k-picker-wrap.k-state-focused {
  
  border: none !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
 
 .error-fragment .k-dropdown-wrap > .k-input,.error-fragment .k-dropdown .k-dropdown-wrap .k-select, .error-fragment .k-textbox {
   color: black;
   background: none;
 }

.download-dial-wrapper {
  .download-dial {
    padding-bottom: 10px;
    .k-upload {
      border-radius: 5px;
      border: dashed 1px #c4c4c4;
      background-color: #f7f7f7;
      height: 160px;
      width: 100%;

      .k-dropzone.k-dropzone-active .k-dropzone-hint {
        display: none !important;
      }

      .k-dropzone {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
      }

      .k-dropzone::after {
        display: block;
        content: "Or drop your file...";
        opacity: 1;
        color: #808080;
        font-size: 1rem;
      }

      .k-upload-status {
        display: none;
      }
      .k-upload-button {
        border-radius: 5px;
        margin: 0px 20px;
        background-repeat: no-repeat;
        background-size: 80px 80px;
        height: 100px;
        width: 100px;
        background-position: center;
        background-image: url(./assets/icons/main-icons.svg#add-file);
        background-color: transparent;
        > span {
          display: none;
        }
        > input {
          height: 100%;
        }
      }
    }
  }

  .file-list {
    display: flex;
    flex-direction: column;
    .file-list-item {
      display: flex;
      flex-direction: row;
      height: 70px;
      padding: 5px;
      .item-icon {
        width: 50px;
        .upload-i {
          display: inline-block;
          content: " ";
          background-repeat: no-repeat;
          background-size: 40px 40px;
          height: 50px;
          width: 50px;
          background-image: url(./assets/icons/main-icons.svg#excelDownload);
        }
      }
      .item-description {
        width: 500px;
        .progress {
          height: 0.5rem;
        }

        .item-header {
          padding-bottom: 5px;
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          .item-delete {
            text-decoration: none;
            a {
              color: #808080;
            }
          }
        }

        .item-upload-status {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-top: 5px;
        }
        .upload-error-text {
          color: red !important;
        }
        .upload-prepare-text,
        .upload-success-text {
          color: #1f94f2 !important;
        }
      }
    }
  }

  .download-dial-footer {
    padding-top: 5px;
  }
}

.mandatory::after{
  content: " *";
  color: red;
}

.ted-tooltips {
  .multi-line {
    text-align: left;
  }
  &.show {
    opacity: 1 !important;
  }
  .type-light.border.place-right:before{
        border-right: 8px solid #cccccc;
  }
}

.itemAlign{
  position: relative;
  padding-right: 250px !important;
}

.search-bar {
  margin-bottom: 10px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  padding: 5px 15px;

  .k-textbox,
  .k-textarea,
  .k-input.k-textbox {
    font-size: 14px;
  }

  .search-bar-empty-area {
    width: 15.5%;
    margin-top: 5px;
  }

  .search-bar-download-area {
    width: 12%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 5px;

    .btn-refresh {
      background-color: white;
      border: none;
      padding: 0px;
    }

    .btn-refresh:focus {
      outline: none;
    }

    .btn-refresh .refresh-icon {
      display: block;
      content: " ";
      background-image: url("./assets/icons/main-icons.svg#refresh");
      background-size: 24px 24px;
      margin-right: 5px;
      height: 26px;
      width: 26px;
      border-radius: 2px;
      border: solid 1px #808080;
      background-color: #ffffff;
    }
  }

  .add-btn {
    height: 26px;
    display: flex;
    align-items: flex-end;
    
    & > button {
      padding-top: 0px;
      padding-bottom: 0px;
      background-color: #fff;
      color: $base-color;
      border: 1px solid $base-color !important;
      font-size: $base-btn-font-size;
      box-shadow: none;
      width: 65px;
      text-transform: capitalize;
      margin: 0px 10px;

      &:hover {
        background-color: rgba(234, 245, 243, 0.952);
      }

      & > span {
        margin-right: 0px;
        margin-top: -2px;
      }
    }
  }

  button.add-btn {
    margin-top: 2px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #fff;
    color: $base-color;
    border: 1px solid $base-color !important;
    font-size: $base-btn-font-size;
    box-shadow: none;
    width: 65px;
    text-transform: capitalize;
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
    height: 24px;
    
    &:hover {
      background-color: rgba(234, 245, 243, 0.952);
    }
  }
}

label.ted-status-label {
  border: 1px solid #19bd9b;
  color: #19bd9b;
  border-radius: 20px;
  padding: 0px 10px;
  font-weight: 100;
}


.k-list-container {

  min-width: fit-content;
  .k-list {
    .k-item {
      min-width: 100%;
      white-space: nowrap;
      width: fit-content;
    }
  }
  .k-list-scroller::-webkit-scrollbar-track {
    @extend .webkit-scrollbar-track;
  }
  
  .k-list-scroller::-webkit-scrollbar {
    @extend .webkit-scrollbar;
  }
  
  .k-list-scroller::-webkit-scrollbar-thumb {
    @extend .webkit-scrollbar-thumb;
  }
}

$loading-icon-url: url(./assets/images/loader_gray.gif);
.k-autocomplete {
    @include custom-loading($loading-icon-url)
 }

 .ted-radio-invalid {
  border: 1px solid #ff0000;
  border-radius: 3px;
}

.no-anchor {
  background: unset;
  border: unset;
   
  &:focus {
     outline: 0;
   }
}

table {
  thead tr th {
    &>.k-link {
      text-align: center;
    }
  }}
.k-grid table tr.k-grid-norecords:hover, .k-grid table tr.k-grid-norecords.k-state-hover, .k-grid table td.k-grid-norecords.k-state-focused{
    background-color: rgba(0,0,0,0) !important;
}
.ted-grid{
  .k-grid-norecords{
    width: 100vw !important;
    td{
       border-right: 0 !important;
       width: 100vw !important;
       div{
          width: 100vw;
       }
    }
 }
}
