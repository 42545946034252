@import "./partials/base";
@import "./partials/buttons";
@import "./partials/text";

.App {
  text-align: center;
  /* width: 160%;
  margin-left: -30%; */
  min-width: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input.login-input {
  margin-top: 1%;
}

.container {
  padding: 0px !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1345px !important;
  }
}

.k-input,
input.k-textbox {
  padding: 5px !important;
}

.reset-buttons {
  height: fit-content;
}

// Commented as blue box-shadow is not required
// // style focus
// .k-checkbox:focus+.k-checkbox-label::before,
// .k-checkbox:checked:focus+.k-checkbox-label::before,
// .k-radio:focus+.k-radio-label::before {
//   border-radius: 1px;
//   border: solid 1px #1d94f2 !important;
//   box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
// }

.k-radio:focus+.k-radio-label::before {
  border-radius: 50%;
}