@import "./variables";

.ted-primary-btn {
  background-color: $base-color;
  color: #fff;
  border: 1px solid rgba(32, 53, 128, 0.16);
  border-radius: 10px;
}

.ted-primary-btn:not(:disabled):hover {
  background-color: $base-color-hover;
}

.ted-btn:disabled {
  cursor: default;
  opacity: 0.5;
}

.confirm-btn {
  min-width: 95px !important;
}

.ted-secondary-btn {
  background-color: #eeeeee !important;
  color: #000;
  border: 1px solid rgba(32, 53, 128, 0.16);
  border-radius: 2px;
}

.ted-secondary-btn:not(:disabled):hover {
  background-color: #cecece !important;
}

.ted-btn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: $base-color;
  transition: background-color 0.3s ease 0s, color 0.3s ease 0s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2px;
  padding: 0px 10px;
  font-size: $base-btn-font-size;
  min-width: 65px;
  min-height: 24px;
  border: 1px solid rgba(32, 53, 128, 0.16);

  .k-icon {
    background-size: 12px 12px;
    height: 12px;
    width: 12px;
  }

  &:focus {
    outline: none;
    border: 1px solid $base-color;
    border-radius: 0;
    box-shadow: 0 0 0 0.5rem rgba(0, 123, 255, 0.25);
  }
}

.ted-btn-navigation {
  min-width: 70px;
}

.ted-btn-sm {
  height: $base-component-height;
  width: 65px;
}

.btn-cmd {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50% !important;
  background-position: center;
  transition: background 0.8s;
  color: #fff;

  // &:active {
  // opacity: 0.4;
  // }
  &:focus {
    outline: none;
    border: 1px solid $base-color;
    box-shadow: 0 0 0 0.5rem rgba(0, 123, 255, 0.25);
  }

  .k-icon {
    font-size: 12px;
  }
}

.btn-cmd-remove {
  background-color: #9d9c9c;

  &:hover {
    background-color: #808080;
  }
}

.btn-cmd:disabled {
  cursor: default;
  opacity: 0.5;
}

.btn-cmd-add {
  background-color: $base-color;

  &:hover {
    background-color: $base-color-hover;
  }
}