
.ted-k-calendar-header {
    padding-top: 0px;
    padding-bottom: 0px;
  
    button {
      color: $base-color;
  
    }
  
    & .k-button {
      padding: 0px 16px;
  
      &:hover {
        color: $base-color-hover;
      }
    }
  
    & .k-today {
      color: $base-color;
      font-weight: bold;
    }
  
  
  }
  
  .k-datetime-wrap {
  
    & .k-datetime-buttongroup {
      padding: 4px !important;
  
      & .k-button {
        padding: 1px 6px;
      }
    }
  
  
  
    & .k-datetime-selector {
      & .k-datetime-calendar-wrap {
        & .k-calendar-view {
          height: 16em !important;
  
          & .k-calendar-header {
            @extend .ted-k-calendar-header;
          }
  
          & .k-content.k-scrollable {
            & table {
              & tr {
                & td {
                  height: 2em;
  
                  &.k-today {
                    color: $base-color;
                  }
  
                  &.k-state-pending-focus.k-state-selected {
                    & .k-link {
                      border-color: $base-color;
                      background-color: $base-color;
                    }
                  }
  
                  & .k-link {
                    height: 2em;
                  }
  
                }
              }
  
            }
  
          }
  
        }
  
      }
  
      & .k-datetime-time-wrap {
        & .k-time-part {
          height: 16em !important;
  
          & .k-time-header {
            @extend .ted-k-calendar-header;
          }
  
          & .k-time-list-container {
            & .k-time-list-wrapper {
              height: 130px;
              & .k-title {
                font-size: 12px;
              }
  
              & .k-time-list {
                & .k-content.k-scrollable.k-time-container {
                  & ul {
                    & li {
                      padding-top: 0px;
                      padding-bottom: 0px
                    }
                  }
                }
  
              }
  
  
            }
          }
        }
  
      }
    }
  
    & .k-datetime-footer {
      &.k-action-buttons {
        padding: 4px 8px;
  
        & button {
          line-height: 8px; //align
          color: $base-color;
          font-weight: bold;
        }
      }
    }
  
  }
  
  .k-widget.k-calendar.k-calendar-infinite {
  
    & .k-calendar-view.k-calendar-monthview {
      height: 18em;
  
      & .k-calendar-header {
        @extend .ted-k-calendar-header;
      }
  
  
      & .k-content.k-scrollable {
        & table {
          & tr {
            & td {
              height: 2em;
  
              &.k-today {
                color: $base-color;
              }
  
              &.k-state-pending-focus.k-state-selected {
                & .k-link {
                  border-color: $base-color;
                  background-color: $base-color;
                }
              }
  
              & .k-link {
                height: 2em;
              }
  
            }
          }
  
        }
      }
    }
  }
  