@import "./variables";
@import "./scrollbar";

//reset

.k-textbox,
.k-textarea,
.k-input.k-textbox {
  font-size: 14px;
  height: $base-component-height;
}

button,
input,
optgroup,
select,
textarea {
  height: $base-component-height;
  border-radius: $base-border-radius !important;
}

.k-autocomplete,
.k-combobox,
.k-dateinput,
.k-datepicker,
.k-datetimepicker,
.k-dropdown,
.k-multiselect,
.k-numerictextbox,
.k-maskedtextbox,
.k-timepicker,
.k-dropdowntree {
  font-size: 14px;
  height: $base-component-height;
}

.k-autocomplete .k-input,
.k-dateinput-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-picker-wrap .k-input,
.k-multiselect-wrap .k-input,
.k-numeric-wrap .k-input {
  height: $base-component-child-input-height;
  border-radius: $base-border-radius;
}

.k-textbox,
.k-textarea,
.k-listbox,
.k-picker-wrap,
.k-autocomplete,
.k-numerictextbox>.k-numeric-wrap,
.k-dropdown-wrap,
.k-multiselect-wrap {
  border-radius: 2px;
  border: 1px solid $base-component-border-color;
}



.k-textbox:hover:not(:read-only),
.k-textarea:hover,
.k-autocomplete:hover,
.k-autocomplete.k-state-hover,
.k-dropdown-wrap:hover,
.k-dropdown-wrap.k-state-hover,
.k-picker-wrap:hover,
.k-picker-wrap.k-state-hover,
.k-multiselect-wrap:hover,
.k-multiselect-wrap.k-state-hover,
.k-numeric-wrap:hover,
.k-numeric-wrap.k-state-hover {
  background-color: #f8f8f8;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: none;
  border-style: solid;
  border-color: $base-color;
  border-width: 1px !important;
}

.k-numeric-wrap:focus-within,
.k-listbox:focus,
.k-textarea:focus,
.k-textbox:focus:not(:read-only) {
  background-color: #f8f8f8;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: none;
  border-style: solid;
  border-color: $base-color;
  border-width: 1px !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  -moz-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}



.k-autocomplete.k-state-focused,
.k-state-focused>.k-autocomplete,
.k-dropdown-wrap.k-state-focused,
.k-state-focused>.k-dropdown-wrap,
.k-picker-wrap.k-state-focused,
.k-datepicker .k-picker-wrap.k-state-focused,
.k-state-focused>.k-picker-wrap,
.k-multiselect-wrap.k-state-focused,
.k-state-focused>.k-multiselect-wrap,
.k-numeric-wrap.k-state-focused,
.k-state-focused>.k-numeric-wrap {
  background-color: #f8f8f8;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: none;
  border-style: solid;
  border-color: $base-color !important;
  border-width: 1px !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  -moz-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.k-textbox.k-state-invalid:focus:not(:read-only),
.k-numeric-wrap.k-state-invalid:focus-within,
.k-widget.k-dropdown.k-header.k-state-invalid .k-state-focused,
.k-state-invalid .k-dropdown-wrap.k-state-focused,
.k-state-invalid .k-picker-wrap.k-state-default.k-state-focused,
.k-autocomplete.k-state-focused.k-state-invalid,
.k-textarea.k-state-invalid:focus  {
  border-color: rgba(243, 23, 0, 1) !important;
  border-radius:$base-border-radius;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(243, 23, 0, 0.25) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(243, 23, 0, 0.25) !important;
  box-shadow: 0 0 0 0.2rem rgba(243, 23, 0, 0.25) !important;
}


.k-state-disabled .k-select{
  background-color: #f1f1f1 !important;
}

.k-textbox:disabled,
.k-picker-wrap.k-state-disabled,
.k-dropdown-wrap.k-state-disabled,
.ted-input-text.k-state-disabled,
.k-numeric-wrap.k-state-disabled,
.k-autocomplete.k-state-disabled,
.k-textarea:disabled {
  background-color: #f1f1f1;
  border: 1px solid $base-component-border-color;
}


.ted-input-text-disabled:read-only{
  cursor: default;
  background-color: #f1f1f1;
  border: 1px solid #c8c8c8;
}

.k-state-invalid>.k-dropdown-wrap,
.k-state-invalid>.k-picker-wrap,
.k-state-invalid>.k-multiselect-wrap,
.k-state-invalid>.k-numeric-wrap,
.k-state-invalid>.k-listbox,
.k-textbox.k-state-invalid,
.k-textarea.k-state-invalid,
.k-numeric-wrap.k-state-invalid {
  border-color: rgba(243, 23, 0, 0.5) !important;
}

// .k-state-invalid > .k-dropdown-wrap.k-state-disabled ,
// .k-state-invalid > .k-picker-wrap.k-state-disabled ,
// .k-state-invalid > .k-multiselect-wrap.k-state-disabled ,
// .k-state-invalid > .k-numeric-wrap.k-state-disabled ,
// .k-state-invalid > .k-listbox, 
// .k-textbox.k-state-invalid[disabled],
// .k-numeric-wrap.k-state-invalid {
//   border-color: rgba(243, 23, 0, 1) !important;
//  }

//disabled text normally already has opacity ==> need override
.k-textbox.k-state-invalid[disabled],
.k-textarea.k-state-invalid[disabled] {
  border: 1px solid rgba(243, 23, 0, 1) !important;
}


.k-dropdown-wrap.k-state-disabled .k-input{
  background: transparent;
}

.k-combobox .k-icon.k-clear-value {
  top: 2px;
}

.k-radio:checked+.k-radio-label::before {
  background-color: white;
  border-color: #1f94f2;
  box-shadow: 0 0 1px 0 white inset;
}

.k-radio:checked+.k-radio-label::after {
  background-color: #1f94f2;
}

.k-radio-label::after {
  background-color: #1f94f2;
  transition: transform linear 280ms;
}

.k-list .k-item:focus,
.k-list .k-item.k-state-focused,
.k-list-optionlabel:focus,
.k-list-optionlabel.k-state-focused {

  background-color: rgba(0, 0, 0, 0.1);
}

.k-textarea {
  overflow-y: hidden;
}

textarea {
  scrollbar-color: rgb(161, 161, 161) #f5f5f5;
  scrollbar-width: thin;
}

textarea::-webkit-scrollbar {
  @extend .webkit-scrollbar;
}

textarea::-webkit-scrollbar-track {
  @extend .webkit-scrollbar-track;
}

textarea::-webkit-scrollbar-thumb {
  @extend .webkit-scrollbar-thumb;
}

.ted-i-icon {
  display: inline-block;
  content: " ";
  background-repeat: no-repeat;
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
}